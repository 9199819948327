<template>
  <div
    class="op-card"
    :class="stagnationAlert && opportunity.status !== 'Ganho' ? 'alert' : ''"
    @click="$router.push(`/opportunity/edit/${opportunity.id}`)"
    :style="{ borderLeftColor: stepColor }"
  >
    <div
      v-if="opportunity.status !== 'Aberto'"
      class="ribbon"
      :data-status="opportunity.status"
    >
      <span>{{ opportunity.status }}</span>
    </div>

    <div
      v-if="opportunity.situation !== '' && opportunity.situation !== 'Nenhuma'"
      class="ribbon"
      :data-situation="opportunity.situation"
    >
      <span>{{ opportunity.situation }}</span>
    </div>
    <div class="row title dotted-phrase">
      {{ opportunity.id }} - {{ opportunity.title }}
    </div>
    <div class="row origin truncate">
      <img
        src="@/assets/images/dashboard/opportunities/target.png"
        width="11"
        alt="target"
      />
      {{ opportunity.origin }}
    </div>
    <div class="row customer dotted-phrase">
      <img
        src="@/assets/images/dashboard/opportunities/user.png"
        width="11"
        alt="user"
      />
      {{
        opportunity.customer
          ? `${opportunity.customer.id} - ${opportunity.customer.first_name} ${opportunity.customer.last_name}`
          : opportunity.customer_name
      }}
    </div>
    <div class="row date">
      <img
        src="@/assets/images/dashboard/opportunities/calendar.png"
        alt="calendar"
        width="11"
      />
      {{ formatDateTime(opportunity.created) }}
    </div>
    <div class="row extra">
      <a-row type="flex" justify="space-between">
        <a-col>
          <img
            v-if="theProducts().includes('hotel')"
            class="mr-5"
            src="@/assets/images/dashboard/opportunities/hotel.png"
            width="14"
            alt="hotel"
          />
          <img
            v-if="theProducts().includes('flight')"
            class="mr-5"
            src="@/assets/images/dashboard/opportunities/flight.png"
            width="12"
            alt="flight"
          />
          <img
            v-if="theProducts().includes('service')"
            class="mr-5"
            src="@/assets/images/dashboard/opportunities/service.png"
            width="14"
            alt="service"
          />
        </a-col>
        <a-col>
          <i
            v-if="haveWhatsappConversations && !loadingWhatsappConversations"
            class="fa-brands fa-whatsapp mr-5"
          ></i>

          <a-icon v-if="loadingWhatsappConversations" type="loading" />

          <a-icon type="paper-clip" class="mr-3" style="opacity: 0.6" />
          <span class="mr-5" style="opacity: 0.8">{{
            opportunity.total_files
          }}</span>
          <img
            src="@/assets/images/dashboard/opportunities/notes-ico.png"
            width="11"
            style="opacity: 0.3"
            alt="notes"
          />
          <span class="ml-5" style="opacity: 0.8">{{
            opportunity.total_notes
          }}</span>
        </a-col>
      </a-row>
    </div>
    <div class="avatar">
      <div v-if="theOptUser.id !== undefined">
        <a-avatar
          v-if="theOptUser.avatar"
          :src="theOptUser.avatar"
          :size="25"
        />
        <a-avatar
          v-else
          class="upper"
          style="color: #f56a00; background-color: #fde3cf"
        >
          {{ theOptUser.first_name.substring(0, 1)
          }}{{ theOptUser.last_name.substring(0, 1) }}
        </a-avatar>
      </div>
      <div v-else>
        <a-avatar :size="25" icon="user" />
      </div>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
export default {
  name: "OpportunityCard",
  props: {
    opportunity: Object,
    stepColor: String,
    stagnationAlert: Boolean,
    usersList: Array,
  },
  mixins: [formatThings],
  data() {
    return {
      loadingWhatsappConversations: false,
      haveWhatsappConversations: false,
      theOptUser: {},
    };
  },
  mounted() {
    this.loadingWhatsappConversations = true;
    this.theOptUser = this.opportunity.user;
    this.$integrations
      .get(
        `/integrations/robbu/messageoutgoing?order=DESC&orderBy=sentat&page=1&pageSize=1&destinationphonenumber=${this.formatPhoneNumber()}`
      )
      .then(({ data }) => {
        let theConsultant = this.usersList.filter((user) => {
          return data.meta.consultant === user.meta.user_robbu;
        });

        if (
          !["Site VO", "Site VR", "Site VS", "Site VT"].includes(
            this.opportunity.origin
          ) &&
          theConsultant.length &&
          this.opportunity.raw.user_id == 0 &&
          data.meta.consultant
        ) {
          this.$http
            .post(`/opportunity/update-field`, {
              id: this.opportunity.id,
              field: "user_id",
              value: theConsultant[0].id,
            })
            .then(() => {
              this.theOptUser = theConsultant[0];

              this.$http.post("/log/create", {
                user_id: -1,
                module_id: this.opportunity.id,
                module: "opportunity",
                action: "auto-change-opportunity-user",
                description: `O <b>Sistema</b> atribuiu a oportunidade automaticamente para <b>${theConsultant[0].first_name} ${theConsultant[0].last_name}</b>. Ação realizada por conversa iniciada pelo consultor na plataforma <b>Robbu</b>.`,
              });

              this.$http.post("/notification/create", {
                user_id: theConsultant[0].id,
                created_by: 0,
                title: `Nova oportunidade recebida!`,
                content: `O sistema encaminhou a oportunidade ID ${this.opportunity.id} pra você. Para ver detalhes clique nesta notificação.`,
                action: `/opportunity/edit/${this.opportunity.id}`,
                is_read: 0,
              });
            });
        }

        this.haveWhatsappConversations = data.data.length ? true : false;
      })
      .finally(() => (this.loadingWhatsappConversations = false));
  },
  methods: {
    formatPhoneNumber() {
      const thePhone =
        this.opportunity.customer.mobile_phone ?? this.opportunity.mobile_phone;

      return thePhone
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace(" ", "")
        .replace(" ", "")
        .replace("-", "");
    },
    theProducts() {
      let contractedServices = [],
        hotel = [],
        flight = [],
        service = [];

      Object.keys(this.opportunity.meta).forEach((key) => {
        if (key.includes("hotel")) hotel.push(key);
        if (key.includes("flight")) flight.push(key);
        if (key.includes("service")) service.push(key);
      });

      if (service.length > 0) contractedServices.push("service");
      if (flight.length > 0) contractedServices.push("flight");
      if (hotel.length > 0) contractedServices.push("hotel");

      return contractedServices;
    },
  },
};
</script>

<style lang="sass" scoped>

.ribbon
  height: 50px
  overflow: hidden
  position: absolute !important
  width: 57px !important
  top: -7px
  left: -12px
  span
    font-size: 7px
    font-weight: 500
    color: #FFF
    text-transform: uppercase
    text-align: center
    line-height: 14px
    transform: rotate(-45deg)
    -webkit-transform: rotate(-45deg)
    width: 63px
    display: block
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1)
    position: absolute
    top: 12px
    left: -11px
  span::before
    content: ""
    position: absolute
    left: 0px
    top: 96%
    z-index: -1
  span::after
    content: ""
    position: absolute
    right: 0px
    top: 100%
    z-index: -1
.ribbon[data-status="Perdido"] span
  background: linear-gradient(#F70505 0%, #8F0808 100%)
.ribbon[data-status="Perdido"] span::before
  border-left: 3px solid #4b0808
  border-right: 3px solid transparent
  border-bottom: 3px solid transparent
  border-top: 3px solid #4b0808
.ribbon[data-status="Perdido"] span::after
  border-left: 3px solid transparent
  border-right: 3px solid #4b0808
  border-bottom: 3px solid transparent
  border-top: 3px solid #4b0808

.ribbon[data-status="Ganho"] span
  background: linear-gradient(#9BC90D 0%, #79A70A 100%)
.ribbon[data-status="Ganho"] span::before
  border-left: 3px solid #384b0a
  border-right: 3px solid transparent
  border-bottom: 3px solid transparent
  border-top: 3px solid #384b0a
.ribbon[data-status="Ganho"] span::after
  border-left: 3px solid transparent
  border-right: 3px solid #384b0a
  border-bottom: 3px solid transparent
  border-top: 3px solid #384b0a


.ribbon[data-situation="Congelado"] span
  background: linear-gradient(#2989d8 0%, #1e5799 100%)
.ribbon[data-situation="Congelado"] span::before
  border-left: 3px solid #13244b
  border-right: 3px solid transparent
  border-bottom: 3px solid transparent
  border-top: 3px solid #13244b
.ribbon[data-situation="Congelado"] span::after
  border-left: 3px solid transparent
  border-right: 3px solid #13244b
  border-bottom: 3px solid transparent
  border-top: 3px solid #13244b

.ribbon[data-situation="Lixeira"] span
  background: linear-gradient(#B6BAC9 0%, #808080 100%)
.ribbon[data-situation="Lixeira"] span::before
  border-left: 3px solid #4b4b4b
  border-right: 3px solid transparent
  border-bottom: 3px solid transparent
  border-top: 3px solid #4b4b4b
.ribbon[data-situation="Lixeira"] span::after
  border-left: 3px solid transparent
  border-right: 3px solid #4b4b4b
  border-bottom: 3px solid transparent
  border-top: 3px solid #4b4b4b



.op-card
  cursor: pointer
  position: relative
  background: #f5f7fa
  height:100px
  padding: 8px 6px
  box-shadow: rgb(0 0 0 / 15%) 0 0 5px
  border-radius: 6px
  border: 1px solid #d6d6d6
  border-left: 5px solid #ed5565
  margin-bottom: 10px
  &.alert
    background: #fcf4d2
  .title
    font-weight: 600
    padding-right: 30px
  .origin
    width: 180px
  .row
    font-size: 11px
    line-height: 1.3
    color: #59595b
    margin: 0 0 3px 0
    img
      position: relative
      top: -1px
  .avatar
    position: absolute
    right: 3px
    top: 3px
</style>
